var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.selected_intervenant !== null)?_c('v-card',{staticClass:"mx-0 pa-2",attrs:{"elevation":"0","outlined":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"3"}},[(_vm.hasPrestataire(_vm.selected_intervenant))?_c('h3',{staticClass:"secondary--text"},[_vm._v(_vm._s(_vm.prestataire.raison_sociale))]):_vm._e(),_c('h3',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.selected_intervenant.first_name)+" "+_vm._s(_vm.selected_intervenant.last_name))])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"3"}},[(_vm.isResponsableOrAdmin || _vm.hasPermission('change_profile'))?_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.editIntervenant()}}},[_c('v-icon',[_vm._v("mdi-pencil")]),_vm._v(" Modifier ")],1):_vm._e(),(_vm.isResponsableOrAdmin || _vm.hasPermission('delete_profile'))?_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"error"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")]),_vm._v(" Supprimer ")],1)]}}],null,false,942478771),model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline red lighten-1 white--text"},[_vm._v(" Supprimer l'intervenant ")]),_c('v-card-text',{staticClass:"body-1 mt-2"},[_vm._v(" Confirmer la suppression de l'intervenant ? ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":_vm.deleteIntervenant}},[_vm._v("Supprimer l'intervenant ")])],1)],1)],1):_vm._e()],1)],1)],1),(_vm.hasProfile(_vm.selected_intervenant) && _vm.selected_intervenant.profile.metiers !== null)?_c('v-col',{staticClass:"mb-2",attrs:{"cols":"12"}},_vm._l((_vm.selected_intervenant.profile.metiers),function(metier){return _c('v-chip',{key:metier.id,attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(metier.name)+" ")])}),1):_vm._e(),_c('v-col',{attrs:{"cols":"6","xl":"3"}},[_c('detail-card',{attrs:{"icon":"mdi-email","name":"E-mail","information":_vm.selected_intervenant.email}})],1),_c('v-col',{attrs:{"cols":"6","xl":"3"}},[_c('detail-card',{attrs:{"icon":"mdi-account-multiple","name":"Groupe","information":_vm.selected_intervenant.group}})],1),_c('v-col',{attrs:{"cols":"6","xl":"3"}},[_c('detail-card',{attrs:{"icon":"mdi-cellphone","name":"Tel mobile","information":_vm.hasProfile(_vm.selected_intervenant)
                                 && _vm.selected_intervenant.profile.tel_mobile !== null
                                 ? _vm.selected_intervenant.profile.tel_mobile : 'Non défini'}})],1),_c('v-col',{attrs:{"cols":"6","xl":"3"}},[_c('detail-card',{attrs:{"icon":"mdi-phone","name":"Tel fixe","information":_vm.hasProfile(_vm.selected_intervenant)
                                 && _vm.selected_intervenant.profile.tel_fixe !== null
                                 ? _vm.selected_intervenant.profile.tel_fixe : 'Non défini'}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center"}},_vm._l((_vm.selected_intervenant.profile.sites),function(site){return _c('v-col',{key:site.id,attrs:{"cols":"6","xl":"4"}},[_c('detail-card',{attrs:{"name":"Site","icon":"mdi-map-marker","information":_vm.site_name(site)}})],1)}),1)],1),(_vm.hasPrestataire(_vm.selected_intervenant))?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Prestataire")])]),_c('v-col',{attrs:{"cols":"6","xl":"3"}},[_c('detail-card',{attrs:{"icon":"mdi-map-marker","name":"Adresse","long":"","information":_vm.hasPrestataire(_vm.selected_intervenant)
                                     && _vm.prestataire.adresse !== null
                                     ? _vm.prestataire.adresse : 'Non défini'}})],1),_c('v-col',{attrs:{"cols":"6","xl":"3"}},[_c('detail-card',{attrs:{"icon":"mdi-google-maps","name":"Code postal","information":_vm.hasPrestataire(_vm.selected_intervenant)
                                 ? _vm.prestataire.code_postale
                                 : 'Non défini'}})],1),_c('v-col',{attrs:{"cols":"6","xl":"3"}},[_c('detail-card',{attrs:{"icon":"mdi-office-building","name":"SIRET","information":_vm.hasPrestataire(_vm.selected_intervenant)
                                 ? _vm.prestataire.siret
                                 : 'Non défini'}})],1),_c('v-col',{attrs:{"cols":"12","xl":"6"}},[_c('detail-card',{attrs:{"icon":"mdi-ticket","name":"Reçoit directement les tickets","information":_vm.hasPrestataire(_vm.selected_intervenant)
                                 ? _vm.prestataire.direct_ticket ? 'Oui' : 'Non'
                                 : 'Non défini'}})],1),(_vm.prestataire.contrats.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Contrats du prestataire")])]),_vm._l((_vm.prestataire.contrats),function(contrat){return _c('v-col',{key:contrat.id,attrs:{"cols":"4"}},[_c('detail-card',{attrs:{"clickable":"","name":_vm.getContractType(contrat),"long":"","information":contrat.name,"icon":"mdi-file-document-multiple-outline"},nativeOn:{"click":function($event){return _vm.$router.push('/contracts/' + contrat.id)}}})],1)})],2)],1):_vm._e()],1):_vm._e(),(_vm.hasProfile(_vm.selected_intervenant))?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"10"}},[_c('p',{staticClass:"body-1"},[_c('strong',[_vm._v(_vm._s(_vm.selected_intervenant.profile.documents.length)+" pièce"),(_vm.selected_intervenant.profile.documents.length > 1)?_c('span',[_vm._v("s")]):_vm._e(),_vm._v(" jointe"),(_vm.selected_intervenant.profile.documents.length > 1)?_c('span',[_vm._v("s")]):_vm._e()])])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                 var on = ref.on;
                                 var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"elevation":"2","color":"primary","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,863090453),model:{value:(_vm.attachmentDialog),callback:function ($$v) {_vm.attachmentDialog=$$v},expression:"attachmentDialog"}},[_c('PieceJointeForm',{attrs:{"type":"profile"},on:{"close-dialog":function($event){_vm.attachmentDialog = false;}}})],1)],1),_vm._l((_vm.selected_intervenant.profile.documents),function(attachment){return _c('v-col',{key:attachment.id,staticClass:"mb-2 pa-0 mini-card",attrs:{"cols":"6","xl":"3"}},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"10"}},[_c('v-list-item',{attrs:{"two-line":"","href":attachment.file,"target":"_blank"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(attachment.label))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.getFilename(attachment.path)))])],1)],1)],1),_c('v-col',{staticClass:"align-center justify-center pa-0",attrs:{"cols":"2"}},[_c('v-icon',{on:{"click":function($event){return _vm.deleteAttachment(attachment)}}},[_vm._v("mdi-trash-can-outline")])],1)],1)],1)})],2)],1):_vm._e()],1),_c('v-row',[_c('v-divider')],1),_c('v-row',{staticClass:"py-4 ml-3",attrs:{"no-gutters":""}},[_c('v-col')],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }